import * as React from "react"
import { Link } from "gatsby"
import { useSiteMetadata } from "./hooks/useSiteMetadata"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const { title, catalogs } = useSiteMetadata()
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link className="main-heading-link" to="/">
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h1 className="secondary-heading">
        <Link className="secondary-heading-link" to="/">
          {title}
        </Link>
      </h1>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <nav className="top-menu">
        {catalogs.map((cat, index) => {
          const spacer = catalogs.length === index + 1 ? " " : " | "
          return (
            <>
              <Link
                className="top-menu-link"
                key={index}
                to={`/${cat}`}
                alt={cat}
              >
                {cat}
              </Link>
              {spacer}
            </>
          )
        })}
      </nav>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer className="footer">
        <p className="bottom-copyright">
          © {new Date().getFullYear()} {title}, Built with
          {` `}
          <a
            href="https://www.gatsbyjs.com"
            className="gatsby-reference-link"
            alt="GatsbyJS"
          >
            Gatsby
          </a>
        </p>
        <nav className="bottom-menu">
          {catalogs.map((cat, index) => {
            const spacer = catalogs.length === index + 1 ? " " : " | "
            return (
              <>
                <Link
                  className="top-menu-link"
                  key={index}
                  to={`/${cat}`}
                  alt={cat}
                >
                  {cat}
                </Link>
                {spacer}
              </>
            )
          })}
        </nav>
      </footer>
    </div>
  )
}

export default Layout
